import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import Button from "pages/_components/Button";
import { routerActions } from "react-router-redux/actions";
import FormattedAmount from "pages/_components/FormattedAmount";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import Tooltip from "pages/_components/Tooltip";
import Selector from "pages/_components/fields/formik/Selector";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import * as config from "util/config";
import Col from "react-bootstrap/lib/Col";
import Row from "pages/_components/Row";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string, arrayOf } from "prop-types";
import useAsyncTicketProcessing from "hooks/useAsyncTicketProcessing";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import FormTransition from "../_components/FormTransition";
import { useGenericProps, useMetaData } from "./hooks/TransferInternalHooks";
import { PDFTextSimple, PDFTextField, PDFhighlightedField } from "./PDFTicket";

const ID_FORM = "debitCards.renew";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
const TITLE_FORM = "forms.debitCards.renew.formName";

const deliveryMethodCodes = {
    BRANCH: "BRANCH",
    HOME: "HOME",
    WORK: "WORK",
};

const RenewDebitCard = (props) => {
    const { mode, dispatch, location, preDataForm, transaction, isDesktop, form } = props;

    const [TooltipProcessingComponent] = useAsyncTicketProcessing(transaction);
    const [branchesList, setBranchesList] = useState();
    const [feesList, setFeesList] = useState();
    const [deliveryMethods, setDeliveryMethods] = useState();

    const [maskNumber, setMaskNumber] = useState();
    const [debitCardID, setDebitCardID] = useState();
    const [branchOption, setBranchOption] = useState("");

    const [deliveryMethod, setDeliveryMethod] = useState("");
    const [deliveryAmount, setDeliveryAmount] = useState("");
    const [itbmsAmount, setITBMSAmount] = useState("");
    const [deliveryBalanceTotal, setDeliveryBalanceTotal] = useState(0.0);

    const [residenceAddress, setResidenceAddress] = useState("");
    const [employmentAddress, setEmploymentAddress] = useState("");

    const [residenceTotalAmount, setResidenceTotalAmount] = useState("");
    const [employmentTotalAmount, setEmploymentTotalAmount] = useState("");
    const branchTotalAmount = "USD 0.00";

    // residence
    const [residenceCountryId, setResidenceCountryId] = useState();
    const [residenceCountry, setResidenceCountry] = useState();
    const [residenceProvince, setResidenceProvince] = useState();
    const [residenceDistrict, setResidenceDistrict] = useState();
    const [residenceJurisdiction, setResidenceJurisdiction] = useState();
    const [residenceCity, setResidenceCity] = useState();
    const [residenceReference, setResidenceReference] = useState();
    const [residenceStreet, setResidenceStreet] = useState();
    const [residenceBuilding, setResidenceBuilding] = useState();
    const [residenceHouse, setResidenceHouse] = useState();
    const [residencePhoneNumber, setResidencePhoneNumber] = useState();

    // employment
    const [employmentCountryId, setEmploymentCountryId] = useState();
    const [employmentCountry, setEmploymentCountry] = useState();
    const [employmentProvince, setEmploymentProvince] = useState();
    const [employmentDistrict, setEmploymentDistrict] = useState();
    const [employmentJurisdiction, setEmploymentJurisdiction] = useState();
    const [employmentCity, setEmploymentCity] = useState();
    const [employmentReference, setEmploymentReference] = useState();
    const [employmentStreet, setEmploymentStreet] = useState();
    const [employmentBuilding, setEmploymentBuilding] = useState();
    const [employmentHouse, setEmploymentHouse] = useState();
    const [employmentPhoneNumber, setEmploymentPhoneNumber] = useState();

    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);
    const [genericProps] = useGenericProps(props, ID_ACTIVITY);

    const titleForm = TITLE_FORM;

    const buildCompleteAddress = (data) => {
        if (data.countryCode === "PA") {
            return `${data.street}, ${data.buildingDesc}, ${data.houseNumber}, ${data.addressDesc}, ${data.province}, ${data.country}, ${data.jurisdiction}`;
        }
        return `${data.street}, ${data.buildingDesc}, ${data.houseNumber}, ${data.city},  ${data.province}, ${data.country}`;
    };

    const getTotalCost = (countryId) => {
        const balance = preDataForm.fees.find((item) => item.countryCode === countryId || item.countryCode === "INT");

        return `USD ${parseFloat(balance.countryFee) + parseFloat(balance.ITBMSFee)}`;
    };

    useEffect(() => {
        if (mode === "edit") {
            const params = new URLSearchParams(location.search);

            const debitCard = params.get("debitCard").replaceAll(" ", "+");
            setDebitCardID(debitCard);

            const mask = params.get("mask");
            const franchise = params.get("franchise");
            setMaskNumber(`${mask} ${franchise}`);

            const requestData = { cardID: debitCard };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
        }
    }, [dispatch, mode, location]);

    useEffect(() => {
        if (preDataForm) {
            if (preDataForm?.branches) {
                const parsedBranchList = preDataForm.branches.map((branch) => ({
                    value: branch.id,
                    label: branch.label,
                }));
                setBranchesList(parsedBranchList);
            }
            setFeesList(preDataForm?.fees);

            if (preDataForm?.methods && preDataForm?.residenceData) {
                const methodsList = preDataForm.methods
                    .filter(
                        (method) =>
                            !(preDataForm.residenceData.countryCode !== "PA" && method === deliveryMethodCodes.BRANCH),
                    )
                    .map((method) => ({
                        id: method,
                        label: i18n.get(`forms.${ID_FORM}.method.${method}.label`),
                    }));
                setDeliveryMethods(methodsList);
            }

            if (preDataForm?.residenceData) {
                setResidenceCountryId(preDataForm?.residenceData.countryCode);
                setResidenceCountry(preDataForm?.residenceData.country);
                setResidenceDistrict(preDataForm?.residenceData.district);
                setResidenceProvince(preDataForm?.residenceData.province);
                setResidenceJurisdiction(preDataForm.residenceData.jurisdiction);
                setResidenceCity(preDataForm.residenceData.city);
                setResidenceReference(preDataForm.residenceData.addressDesc);
                setResidenceStreet(preDataForm.residenceData.street);
                setResidenceBuilding(preDataForm.residenceData.buildingDesc);
                setResidenceHouse(preDataForm.residenceData.houseNumber);
                setResidencePhoneNumber(preDataForm.residenceData.residentialPhoneNumber);

                setResidenceAddress(buildCompleteAddress(preDataForm?.residenceData));
                setResidenceTotalAmount(getTotalCost(preDataForm?.residenceData.countryCode));
            }
            if (preDataForm?.employmentData) {
                setEmploymentCountryId(preDataForm?.employmentData.countryCode);
                setEmploymentCountry(preDataForm?.employmentData.country);
                setEmploymentDistrict(preDataForm?.employmentData.district);
                setEmploymentProvince(preDataForm?.employmentData.province);
                setEmploymentJurisdiction(preDataForm.employmentData.jurisdiction);
                setEmploymentCity(preDataForm.employmentData.city);
                setEmploymentReference(preDataForm.employmentData.addressDesc);
                setEmploymentStreet(preDataForm.employmentData.street);
                setEmploymentBuilding(preDataForm.employmentData.buildingDesc);
                setEmploymentHouse(preDataForm.employmentData.houseNumber);
                setEmploymentPhoneNumber(preDataForm.employmentData.employmentPhoneNumber);

                setEmploymentAddress(buildCompleteAddress(preDataForm?.employmentData));
                setEmploymentTotalAmount(getTotalCost(preDataForm?.employmentData.countryCode));
            }
        }
    }, [preDataForm]);

    useEffect(() => {
        if (deliveryMethod && residenceCountryId && employmentCountryId && feesList) {
            if (residenceCountryId === "PA" && deliveryMethod === deliveryMethodCodes.BRANCH) {
                setDeliveryBalanceTotal(0.0);
                setDeliveryAmount(branchTotalAmount);
                setITBMSAmount(branchTotalAmount);
                return;
            }
            const countryId = deliveryMethod === deliveryMethodCodes.HOME ? residenceCountryId : employmentCountryId;

            const balance =
                feesList.find((item) => item.countryCode === countryId) ||
                preDataForm.fees.find((item) => item.countryCode === "INT");

            if (balance) {
                setDeliveryAmount(`USD ${balance.countryFee}`);
                setITBMSAmount(`USD ${balance.ITBMSFee}`);
                setDeliveryBalanceTotal(parseFloat(balance.countryFee) + parseFloat(balance.ITBMSFee));
            }
        }
    }, [deliveryMethod]);

    const validationSchema = () =>
        Yup.object().shape({
            deliveryMethod: Yup.string().required(i18n.get(`forms.${ID_FORM}.deliveryMethod.required`)),
            branchOption: Yup.string().when("deliveryMethod", {
                is: deliveryMethodCodes.BRANCH,
                then: Yup.string().required(i18n.get(`${ID_FORM}.branchOption.required`)),
                otherwise: Yup.string().notRequired(),
            }),
        });

    const renderTicket = (values) => (
        <>
            {values?.maskNumber && (
                <PDFhighlightedField
                    value={values?.maskNumber}
                    label={i18n.get(`forms.${ID_FORM}.debitCard.title_maskNumber`)}
                    idTransactionStatus={transaction.idTransactionStatus}
                />
            )}
            <PDFTextField
                idForm={ID_FORM}
                value={
                    transaction?.idParentTransaction
                        ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                        : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                }
                label={i18n.get(`forms.${ID_FORM}.date.label_preview`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="motive"
                value={i18n.get(`forms.${ID_FORM}.motive.value`)}
                label={i18n.get(`forms.${ID_FORM}.motive`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="deliveryAmount"
                label={i18n.get(`forms.${ID_FORM}.amount.delivery`)}
                value={values?.deliveryTotalAmount}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="deliveryAddress"
                label={i18n.get(`forms.${ID_FORM}.delivery.address`)}
                value={values?.deliveryAddress}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="estimatedDeliveryTime"
                label={i18n.get(`forms.${ID_FORM}.estimated.delivery.time`)}
                value={config.get(`renew.debit.card.estimated.delivery.time.${values?.deliveryMethod}`)}
            />
            {values?.deliveryMethod === deliveryMethodCodes.BRANCH && (
                <PDFTextSimple text={i18n.get(`forms.${ID_FORM}.ticket.disclaimer`)} />
            )}
        </>
    );

    const renderDeliveryBalance = () => (
        <Box
            component="article"
            display="flex"
            background="heading-color"
            borderRadius="default"
            alignX="between"
            alignY="center"
            className="pl-lg-10 p-5 mb-5 mt-6 pt-7 pb-7">
            <Box className="mb-3" display="flex" alignY="center">
                <Text size="5" color="inverse" labelKey={`forms.${ID_FORM}.deliveryBalance`} />
            </Box>
            <Box className="mb-3" display="flex" alignX="flex-end">
                <FormattedAmount
                    size={isDesktop ? "big" : "2"}
                    color="inverse"
                    bold
                    quantity={deliveryBalanceTotal || 0}
                    currency="USD"
                />
            </Box>
        </Box>
    );

    const renderResidenceForm = () => {
        const content = () => (
            <Box className="mt-5">
                <Row>
                    <Col md={6}>
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.residenceCountry.label`}
                                name="residenceCountry"
                                valueKey="value"
                                value={residenceCountry}
                                disabled
                            />
                        </Box>
                        {residenceCountryId === "PA" && (
                            <Box className="pb-3">
                                <TextSimpleField
                                    form={form}
                                    labelKey={`forms.${ID_FORM}.residenceDistrict.label`}
                                    name="residenceDistrict"
                                    valueKey="value"
                                    value={residenceDistrict}
                                    disabled
                                />
                            </Box>
                        )}
                        {residenceCountryId !== "PA" && (
                            <Box className="pb-3">
                                <TextSimpleField
                                    form={form}
                                    labelKey={`forms.${ID_FORM}.residenceCity.label`}
                                    name="residenceCity"
                                    valueKey="value"
                                    value={residenceCity}
                                    disabled
                                />
                            </Box>
                        )}

                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.residenceStreet.label`}
                                name="residenceStreet"
                                valueKey="value"
                                value={residenceStreet}
                                disabled
                            />
                        </Box>
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.residencePhoneNumber.label`}
                                name="residencePhoneNumber"
                                valueKey="value"
                                value={residencePhoneNumber}
                                disabled
                            />
                        </Box>
                    </Col>
                    <Col md={6}>
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.residenceProvince.label`}
                                name="residenceProvince"
                                valueKey="value"
                                value={residenceProvince}
                                disabled
                            />
                        </Box>

                        {residenceCountryId === "PA" && (
                            <Box className="pb-3">
                                <TextSimpleField
                                    form={form}
                                    labelKey={`forms.${ID_FORM}.residenceJurisdiction.label`}
                                    name="residenceJurisdiction"
                                    valueKey="value"
                                    value={residenceJurisdiction}
                                    disabled
                                />
                            </Box>
                        )}
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.residenceBuilding.label`}
                                name="residenceBuilding"
                                valueKey="value"
                                value={residenceBuilding}
                                disabled
                            />
                        </Box>
                        {residenceCountryId === "PA" && (
                            <Box className="pb-3">
                                <TextSimpleField
                                    form={form}
                                    labelKey={`forms.${ID_FORM}.residenceReference.label`}
                                    name="residenceReference"
                                    valueKey="value"
                                    value={residenceReference}
                                    disabled
                                />
                            </Box>
                        )}
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.residenceHouse.label`}
                                name="residenceHouse"
                                valueKey="value"
                                value={residenceHouse}
                                disabled
                            />
                        </Box>
                    </Col>
                    <Box display="flex" alignX="flex-end" fullWidth>
                        <Button
                            imageRight
                            image="images/arrowRight.svg"
                            label={`forms.${ID_FORM}.redirectDataUpdate.label`}
                            onClick={() => {
                                dispatch(routerActions.push("/settings"));
                            }}
                            bsStyle="link"
                        />
                    </Box>
                </Row>
            </Box>
        );
        if (mode === "edit") {
            return (
                <Box background="white" borderRadius="default">
                    {content()}
                </Box>
            );
        }
        return <div />;
    };

    const renderEmploymentForm = () => {
        const content = () => (
            <Box className="mt-5">
                <Row>
                    <Col md={6}>
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.employmentCountry.label`}
                                name="employmentCountry"
                                valueKey="value"
                                value={employmentCountry}
                                disabled
                            />
                        </Box>
                        {employmentCountryId === "PA" && (
                            <Box className="pb-3">
                                <TextSimpleField
                                    form={form}
                                    labelKey={`forms.${ID_FORM}.employmentDistrict.label`}
                                    name="employmentDistrict"
                                    valueKey="value"
                                    value={employmentDistrict}
                                    disabled
                                />
                            </Box>
                        )}
                        {employmentCountryId !== "PA" && (
                            <Box className="pb-3">
                                <TextSimpleField
                                    form={form}
                                    labelKey={`forms.${ID_FORM}.employmentCity.label`}
                                    name="employmentCity"
                                    valueKey="value"
                                    value={employmentCity}
                                    disabled
                                />
                            </Box>
                        )}
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.employmentStreet.label`}
                                name="employmentStreet"
                                valueKey="value"
                                value={employmentStreet}
                                disabled
                            />
                        </Box>
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.employmentPhoneNumber.label`}
                                name="employmentPhoneNumber"
                                valueKey="value"
                                value={employmentPhoneNumber}
                                disabled
                            />
                        </Box>
                    </Col>
                    <Col md={6}>
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.employmentProvince.label`}
                                name="employmentProvince"
                                valueKey="value"
                                value={employmentProvince}
                                disabled
                            />
                        </Box>
                        {employmentCountryId === "PA" && (
                            <Box className="pb-3">
                                <TextSimpleField
                                    form={form}
                                    labelKey={`forms.${ID_FORM}.employmentJurisdiction.label`}
                                    name="employmentJurisdiction"
                                    valueKey="value"
                                    value={employmentJurisdiction}
                                    disabled
                                />
                            </Box>
                        )}
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.employmentBuilding.label`}
                                name="employmentBuilding"
                                valueKey="value"
                                value={employmentBuilding}
                                disabled
                            />
                        </Box>
                        {employmentCountryId === "PA" && (
                            <Box className="pb-3">
                                <TextSimpleField
                                    form={form}
                                    labelKey={`forms.${ID_FORM}.employmentReference.label`}
                                    name="employmentReference"
                                    valueKey="value"
                                    value={employmentReference}
                                    disabled
                                />
                            </Box>
                        )}
                        <Box className="pb-3">
                            <TextSimpleField
                                form={form}
                                labelKey={`forms.${ID_FORM}.employmentHouse.label`}
                                name="employmentHouse"
                                valueKey="value"
                                value={employmentHouse}
                                disabled
                            />
                        </Box>
                    </Col>
                    <Box display="flex" alignX="flex-end" fullWidth>
                        <Button
                            imageRight
                            image="images/arrowRight.svg"
                            label={`forms.${ID_FORM}.redirectDataUpdate.label`}
                            onClick={() => {
                                dispatch(routerActions.push("/settings"));
                            }}
                            bsStyle="link"
                        />
                    </Box>
                </Row>
            </Box>
        );
        if (mode === "edit") {
            return (
                <Box background="white" borderRadius="default">
                    {content()}
                </Box>
            );
        }
        return <div />;
    };

    const renderBranchForm = (setFieldValue) => {
        const handleBranchChange = (value) => {
            setBranchOption(value);
            setFieldValue("branchOption", value);
            setFieldValue("deliveryAddress", branchesList.find((item) => item.value === value).label);
            setFieldValue("deliveryTotalAmount", branchTotalAmount);
        };

        const content = () => (
            <Box className="mt-5">
                <Row>
                    <Col>
                        <div>
                            <Text color="heading">{i18n.get(`forms.${ID_FORM}.branch.information.label`)}</Text>
                        </div>
                        <div className="mt-3">
                            <Field
                                idForm={ID_FORM}
                                name="branchOption"
                                component={Selector}
                                options={branchesList}
                                value={branchOption}
                                labelNoMarginTop
                                handleChange={handleBranchChange}
                                searchable
                            />
                        </div>
                    </Col>
                </Row>
            </Box>
        );
        if (mode === "edit") {
            return (
                <Box background="white" borderRadius="default">
                    {content()}
                </Box>
            );
        }
        return <div />;
    };

    const renderFees = () => {
        if (!feesList) {
            return <div />;
        }

        return feesList.map((country) => {
            const isPanama = country.countryCode === "PA";
            const countryFee = deliveryMethod === deliveryMethodCodes.BRANCH && isPanama ? "0.00" : country.countryFee;
            const itbmsFee = deliveryMethod === deliveryMethodCodes.BRANCH && isPanama ? "0.00" : country.ITBMSFee;

            return (
                <div>
                    <Text key={country.countryLabel} color="heading" size={6}>
                        {i18n.get(`debitCards.renew.fees.label`, null, {
                            COUNTRY_LABEL: country.countryLabel,
                            COUNTRY_FEE: countryFee,
                            ITBMS_FEE: itbmsFee,
                        })}
                    </Text>
                </div>
            );
        });
    };

    const onChangeDeliveryMethod = (method, setFieldValue, setErrors) => {
        setErrors({});
        setDeliveryMethod(method);
        setFieldValue("deliveryMethod", method);
        setFieldValue("maskNumber", maskNumber);
        setFieldValue("debitCardID", debitCardID);

        if (method === deliveryMethodCodes.BRANCH && branchOption) {
            const branchLabel = branchesList.find((item) => item.value === branchOption).label;
            setFieldValue("deliveryAddress", branchLabel);
            setFieldValue("deliveryTotalAmount", branchTotalAmount);
        }
        if (method === deliveryMethodCodes.HOME) {
            setFieldValue("deliveryAddress", residenceAddress);
            setFieldValue("deliveryTotalAmount", residenceTotalAmount);
        }
        if (method === deliveryMethodCodes.WORK) {
            setFieldValue("deliveryAddress", employmentAddress);
            setFieldValue("deliveryTotalAmount", employmentTotalAmount);
        }
    };

    const renderDeliveryOptions = (setFieldValue, setErrors) => (
        <Box background="white" borderRadius="default" className="pt-3 pl-5 pl-lg-10 pr-5 pr-lg-10 pb-5 mx-n-5">
            <div className="form-group form-group--scheduler mt-3 pd-3">
                <div className="form-group-text">
                    <Text bold size="5" color="primary-color" labelKey={`forms.${ID_FORM}.delivery.options.title`} />
                    <Tooltip text={`forms.${ID_FORM}.delivery.tooltip`} />
                </div>
                <Field
                    {...genericProps}
                    labelNoMarginTop
                    component={FormFieldsComponents.RadioButtonGroupCustom}
                    key="deliveryMethod"
                    name="deliveryMethod"
                    selectorId="deliveryMethod"
                    radioLabelClassName="mr-7"
                    inLineControl={false}
                    onChange={(e) => {
                        onChangeDeliveryMethod(e, setFieldValue, setErrors);
                    }}
                    options={deliveryMethods}
                    value={deliveryMethod}
                />
                <div>
                    {deliveryMethod === deliveryMethodCodes.BRANCH && renderBranchForm(setFieldValue)}
                    {deliveryMethod === deliveryMethodCodes.HOME && renderResidenceForm()}
                    {deliveryMethod === deliveryMethodCodes.WORK && renderEmploymentForm()}
                </div>
            </div>
        </Box>
    );

    const renderFields = (setFieldValue, values, setValues, renderScheduler, errors, setErrors) => {
        if (mode !== "view" && !preDataForm) {
            return <PageLoading loading classicStyle={false} />;
        }
        return (
            <>
                {mode === "edit" && (
                    <>
                        <Row className="mb-lg-7 mb-3">
                            <Col xs={12} md={6}>
                                <Box
                                    background="white"
                                    className="pt-7 pl-5 pl-lg-10 pr-5 pr-lg-10 pb-7 mx-n-5"
                                    borderRadius="default">
                                    <Box display="inline-block" className="mb-2 mb-md-4">
                                        <Text labelKey={`forms.${ID_FORM}.mask.number`} color="heading" bold addColon />
                                        <Text color="heading" uppercase name="maskNumber">
                                            {maskNumber}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box
                                    background="white"
                                    borderRadius="default"
                                    className="pt-7 pl-5 pl-lg-10 pr-5 pr-lg-10 pb-7 mx-n-5 mt-6">
                                    <Box display="inline-block" className="mb-2 mb-md-4">
                                        <Text labelKey={`forms.${ID_FORM}.motive`} color="heading" bold addColon />
                                        <Text color="heading" uppercase name="motive">
                                            {i18n.get(`forms.${ID_FORM}.motive.value`)}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box
                                    background="info"
                                    borderRadius="default"
                                    className="pt-7 pl-5 pl-lg-10 pr-5 pr-lg-10 pb-7 mx-n-5 mt-6">
                                    <Box display="inline-block" className="mb-2 mb-md-4">
                                        <Text> {i18n.get(`forms.${ID_FORM}.delivery.header`)}</Text>
                                        {renderFees()}
                                    </Box>
                                </Box>
                                {renderDeliveryBalance()}
                            </Col>

                            <Col xs={12} md={6}>
                                {renderDeliveryOptions(setFieldValue, setErrors)}
                            </Col>
                        </Row>
                    </>
                )}
                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box
                            display="flex"
                            alignY="center"
                            background={
                                transaction.idTransactionStatus === "FINISHED" ? "success-ticket" : 
                                transaction.idTransactionStatus === "FAILED" ? "error" : 
                                "transparent"
                            }
                            column
                            fullWidth
                            className="pt-4 pb-5 mt-n-min">
                            <Text
                                color={transaction.idTransactionStatus === "FINISHED" ? "inverse" : "heading"}
                                className="mb-2"
                                labelKey={`forms.${ID_FORM}.operation.label`}
                            />
                            <Text
                                color={transaction.idTransactionStatus === "FINISHED" ? "inverse" : "heading"}
                                size="big"
                                bold>
                                {values?.maskNumber}
                            </Text>
                        </Box>

                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={
                                    // eslint-disable-next-line no-nested-ternary
                                    mode === "preview"
                                        ? moment().format("DD/MM/YYYY")
                                        : transaction?.idParentTransaction
                                        ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                                        : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                                }
                                label={`forms.${ID_FORM}.date.label_preview`}
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                label={`forms.${ID_FORM}.motive`}
                                value={i18n.get(`forms.${ID_FORM}.motive.value`)}
                            />

                            {mode === "preview" && (
                                <>
                                    <FormFieldsComponents.ReadTextCustom
                                        {...genericProps}
                                        label={`forms.${ID_FORM}.amount.delivery`}
                                        value={deliveryAmount}
                                    />
                                    <FormFieldsComponents.ReadTextCustom
                                        {...genericProps}
                                        label={`forms.${ID_FORM}.itbms`}
                                        value={itbmsAmount}
                                    />
                                </>
                            )}

                            {mode === "view" && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    label={`forms.${ID_FORM}.amount.delivery`}
                                    value={values?.deliveryTotalAmount}
                                />
                            )}

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                label={`forms.${ID_FORM}.delivery.address`}
                                value={values?.deliveryAddress}
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                label={`forms.${ID_FORM}.estimated.delivery.time`}
                                value={config.get(`renew.debit.card.estimated.delivery.time.${values?.deliveryMethod}`)}
                            />
                            {values?.deliveryMethod === deliveryMethodCodes.BRANCH && (
                                <Text size="7" labelKey={`forms.${ID_FORM}.ticket.disclaimer`} />
                            )}

                            {TooltipProcessingComponent}
                        </Box>
                    </>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: titleForm,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        ticketConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        showFilterChips: false,
        showSchedulerMessage: false,
        onlyBackToDesktopBtn: true,
        validationSchema,
    };
    return <FormTransition {...props} {...formProps} />;
};

RenewDebitCard.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    isDesktop: bool,
    postData: shape({}),
    payCreditCardPermission: bool.isRequired,
    data: arrayOf(shape({})),
    form: shape({}).isRequired,
};
RenewDebitCard.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: shape({}),
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    postData: {},
    data: {},
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
});
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(RenewDebitCard));
